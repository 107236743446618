<template>
    <BIconTextCenter />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { BIconTextCenter } from 'bootstrap-vue'

export default defineComponent({
    name: 'IconTextAlignCenter',
    components: {
        BIconTextCenter,
    },
})
</script>
