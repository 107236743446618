<template>
    <BIconTextLeft />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { BIconTextLeft } from 'bootstrap-vue'

export default defineComponent({
    name: 'IconTextAlignLeft',
    components: {
        BIconTextLeft,
    },
})
</script>
