<template>
    <svg width="41" height="53" viewBox="0 0 41 53" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M30.1471 33.7273V15.6591C30.1471 10.3371 25.8279 6.02273 20.5 6.02273C17.1735 6.02273 14.2402 7.70457 12.5058 10.2634L6.63146 8.35186C9.25981 3.38481 14.4842 0 20.5 0C29.1579 0 36.1765 7.01081 36.1765 15.6591V33.7273C36.1765 42.3756 29.1579 49.3864 20.5 49.3864C11.8421 49.3864 4.82353 42.3756 4.82353 33.7273V21.0795H10.8529V33.7273C10.8529 39.0493 15.1721 43.3636 20.5 43.3636C25.8279 43.3636 30.1471 39.0493 30.1471 33.7273Z"
            fill="#A2A0A3"
        />
        <path
            d="M0 26.5C0 23.5064 2.42951 21.0795 5.42647 21.0795H35.5735C38.5705 21.0795 41 23.5064 41 26.5V47.5795C41 50.5732 38.5705 53 35.5735 53H5.42647C2.42951 53 0 50.5732 0 47.5795V26.5Z"
            fill="#FFB932"
        />
        <path
            d="M20.232 25.7275C20.4196 25.1881 21.1833 25.1881 21.371 25.7275L23.8533 32.8609C23.9361 33.0989 24.1584 33.2602 24.4105 33.2653L31.9694 33.4192C32.541 33.4308 32.777 34.1563 32.3214 34.5013L26.2967 39.0639C26.0957 39.216 26.0108 39.4771 26.0838 39.7182L28.2732 46.9467C28.4387 47.4933 27.8209 47.9416 27.3517 47.6155L21.1459 43.3019C20.9389 43.158 20.6641 43.158 20.4571 43.3019L14.2513 47.6155C13.782 47.9416 13.1642 47.4933 13.3298 46.9467L15.5191 39.7182C15.5921 39.4771 15.5072 39.216 15.3062 39.0639L9.28151 34.5013C8.82597 34.1563 9.06195 33.4308 9.63349 33.4192L17.1924 33.2653C17.4445 33.2602 17.6668 33.0989 17.7496 32.8609L20.232 25.7275Z"
            fill="white"
        />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconUnlocked',
})
</script>
