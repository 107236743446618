<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 4C0 1.79086 1.79086 0 4 0H35C37.2091 0 39 1.79086 39 4V35C39 37.2091 37.2091 39 35 39H4C1.79086 39 0 37.2091 0 35V4Z"
            fill="white"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M35 1.5H4C2.61929 1.5 1.5 2.61929 1.5 4V35C1.5 36.3807 2.61929 37.5 4 37.5H35C36.3807 37.5 37.5 36.3807 37.5 35V4C37.5 2.61929 36.3807 1.5 35 1.5ZM4 0C1.79086 0 0 1.79086 0 4V35C0 37.2091 1.79086 39 4 39H35C37.2091 39 39 37.2091 39 35V4C39 1.79086 37.2091 0 35 0H4Z"
            fill="currentColor"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M34 20.5C34 21.8807 32.8807 23 31.5 23H30.6111C29.2304 23 28.1111 21.8807 28.1111 20.5C28.1111 19.1193 29.2304 18 30.6111 18H31.5C32.8807 18 34 19.1193 34 20.5ZM26.8889 20.5C26.8889 21.8807 25.7696 23 24.3889 23H22.6111C21.2304 23 20.1111 21.8807 20.1111 20.5C20.1111 19.1193 21.2304 18 22.6111 18H24.3889C25.7696 18 26.8889 19.1193 26.8889 20.5ZM18.8889 20.5C18.8889 21.8807 17.7696 23 16.3889 23H14.6111C13.2304 23 12.1111 21.8807 12.1111 20.5C12.1111 19.1193 13.2304 18 14.6111 18H16.3889C17.7696 18 18.8889 19.1193 18.8889 20.5ZM10.8889 20.5C10.8889 21.8807 9.7696 23 8.38889 23H7.5C6.11929 23 5 21.8807 5 20.5C5 19.1193 6.11929 18 7.5 18H8.38889C9.7696 18 10.8889 19.1193 10.8889 20.5Z"
            fill="currentColor"
        />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconDotted',
})
</script>
