<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 4C0 1.79086 1.79086 0 4 0H35C37.2091 0 39 1.79086 39 4V35C39 37.2091 37.2091 39 35 39H4C1.79086 39 0 37.2091 0 35V4Z"
            fill="white"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M35 1.5H4C2.61929 1.5 1.5 2.61929 1.5 4V35C1.5 36.3807 2.61929 37.5 4 37.5H35C36.3807 37.5 37.5 36.3807 37.5 35V4C37.5 2.61929 36.3807 1.5 35 1.5ZM4 0C1.79086 0 0 1.79086 0 4V35C0 37.2091 1.79086 39 4 39H35C37.2091 39 39 37.2091 39 35V4C39 1.79086 37.2091 0 35 0H4Z"
            fill="currentColor"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M34 24C34 25.1046 33.1046 26 32 26H7C5.89543 26 5 25.1046 5 24C5 22.8954 5.89543 22 7 22H32C33.1046 22 34 22.8954 34 24Z"
            fill="currentColor"
        />
        <path
            d="M7.60547 18.8926H9.36914V13.8301C9.36914 13.6348 9.375 13.4297 9.38672 13.2148L8.13867 14.2578C8.08398 14.3008 8.0293 14.3301 7.97461 14.3457C7.92383 14.3613 7.87109 14.3691 7.81641 14.3691C7.73828 14.3691 7.66602 14.3535 7.59961 14.3223C7.5332 14.2871 7.48438 14.248 7.45312 14.2051L6.98438 13.5605L9.63281 11.3105H10.8457V18.8926H12.4102V20H7.60547V18.8926Z"
            fill="currentColor"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15 15.679C15 15.1267 15.4477 14.679 16 14.679L27.2761 14.679C27.2761 14.679 27.2761 15.1267 27.2761 15.679C27.2761 16.2312 27.2704 16.1716 27.2761 16.679H16C15.4477 16.679 15 16.2312 15 15.679Z"
            fill="currentColor"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M26.8366 19.0094C26.7626 19.2003 26.9883 19.4291 27.1702 19.3352L33.9047 15.8562C34.049 15.7816 34.049 15.5753 33.9047 15.5008L27.1702 12.0218C26.9883 11.9278 26.7626 12.1567 26.8366 12.3475C27.1624 13.1881 27.2761 14.3702 27.2761 15.679C27.2761 16.9877 27.1624 18.1688 26.8366 19.0094Z"
            fill="currentColor"
        />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconDirection',
})
</script>
