<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.8333 6.66666V9.16666H2.5V10.8333H10.8333V13.3333L14.1667 9.99999L10.8333 6.66666ZM15.8333 16.6667H17.5V3.33332L15.8333 3.33332V16.6667Z"
            fill="currentColor"
        />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconAlignEnd',
})
</script>
