<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.0416 13.3333V10.8333H18.2083V9.16666H15.0416V6.66666L11.875 9.99999L15.0416 13.3333ZM3.95829 6.66666V9.16666H0.791626L0.791626 10.8333H3.95829L3.95829 13.3333L7.12496 9.99999L3.95829 6.66666ZM8.70829 16.6667H10.2916V3.33332H8.70829V16.6667Z"
            fill="currentColor"
        />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconAlignCenter',
})
</script>
