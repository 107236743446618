<template>
    <BIconTextRight />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { BIconTextRight } from 'bootstrap-vue'

export default defineComponent({
    name: 'IconTextAlignRight',
    components: {
        BIconTextRight,
    },
})
</script>
