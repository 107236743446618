<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.16667 13.3333V10.8333H17.5V9.16666H9.16667V6.66666L5.83333 9.99999L9.16667 13.3333ZM4.16667 3.33333H2.5V16.6667H4.16667V3.33333Z"
            fill="currentColor"
        />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconAlignStart',
})
</script>
