// Color options
export const colorTypeOptions = ['', 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark']
export const outlineColorTypeOptions = [
    'outline-primary',
    'outline-secondary',
    'outline-success',
    'outline-danger',
    'outline-warning',
    'outline-info',
    'outline-light',
    'outline-dark',
]
export const defaultColorOptions = [
    'default-primary',
    'default-secondary',
    'default-success',
    'default-danger',
    'default-warning',
    'default-info',
    'default-light',
    'default-dark',
]

// Text options
export const fontWeightOptions = ['lighter', 'light', 'normal', 'bold', 'bolder']
export const textAlignOptions = ['left', 'center', 'right', 'justify']

// Icon options
export const iconOptions = [
    'A',
    'AlignCenter',
    'AlignEnd',
    'AlignStart',
    'ArrowClockwise',
    'ArrowLeftCircle',
    'BasicText',
    'BlankSpace',
    'CaretRightFill',
    'Check',
    'Checklist',
    'Circle',
    'CircleFill',
    'CircleRecord',
    'Cross',
    'CrossCircle',
    'Delete',
    'Direction',
    'Divider',
    'Dotted',
    'Duplicate',
    'EightDots',
    'Eye',
    'EyeSlash',
    'File',
    'FillInTheBlank',
    'Gear',
    'Gem',
    'Guided',
    'Handwriting',
    'Heart',
    'InfoCircle',
    'Instruction',
    'MagnifyPlus',
    'Matching',
    'Moon',
    'MultipleChoice',
    'OpenResponse',
    'Outline',
    'PageBreak',
    'Palette',
    'Plus',
    'SectionHeader',
    'ShortLine',
    'Smile',
    'Solid',
    'Square',
    'StandardLines',
    'Star',
    'Sun',
    'TextAlignCenter',
    'TextAlignLeft',
    'TextAlignRight',
    'Unlocked',
    'UpDownArrow',
    'WordBank',
    'WordScramble',
    'X',
]

export const iconSizeOptions = ['', 'tiny', 'xs', 'sm', 'lg', 'xl']

// Button options
export const buttonVariantOptions = [...colorTypeOptions, ...outlineColorTypeOptions, ...defaultColorOptions, 'link']
export const buttonSizeOptions = ['', 'tiny', 'xs', 'sm', 'lg']
export const buttonTypeOptions = ['button', 'submit', 'reset']

// Modal options
export const modalSizeOptions = ['', 'sm', 'lg', 'xl', 'full']

// Border radius options
export const borderRadiusOptions = ['all', 'left', 'right']
