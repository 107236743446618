<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 4C0 1.79086 1.79086 0 4 0H35C37.2091 0 39 1.79086 39 4V35C39 37.2091 37.2091 39 35 39H4C1.79086 39 0 37.2091 0 35V4Z"
            fill="white"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M35 1.5H4C2.61929 1.5 1.5 2.61929 1.5 4V35C1.5 36.3807 2.61929 37.5 4 37.5H35C36.3807 37.5 37.5 36.3807 37.5 35V4C37.5 2.61929 36.3807 1.5 35 1.5ZM4 0C1.79086 0 0 1.79086 0 4V35C0 37.2091 1.79086 39 4 39H35C37.2091 39 39 37.2091 39 35V4C39 1.79086 37.2091 0 35 0H4Z"
            fill="currentColor"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M34 24C34 25.1046 33.1046 26 32 26H30.6111C29.5065 26 28.6111 25.1046 28.6111 24C28.6111 22.8954 29.5065 22 30.6111 22H32C33.1046 22 34 22.8954 34 24ZM27.0556 24C27.0556 25.1046 26.1601 26 25.0556 26H22.2778C21.1732 26 20.2778 25.1046 20.2778 24C20.2778 22.8954 21.1732 22 22.2778 22H25.0556C26.1601 22 27.0556 22.8954 27.0556 24ZM18.7222 24C18.7222 25.1046 17.8268 26 16.7222 26H13.9444C12.8399 26 11.9444 25.1046 11.9444 24C11.9444 22.8954 12.8399 22 13.9444 22H16.7222C17.8268 22 18.7222 22.8954 18.7222 24ZM10.3889 24C10.3889 25.1046 9.49346 26 8.38889 26H7C5.89543 26 5 25.1046 5 24C5 22.8954 5.89543 22 7 22H8.38889C9.49346 22 10.3889 22.8954 10.3889 24Z"
            fill="currentColor"
        />
        <path
            d="M7.60547 18.8926H9.36914V13.8301C9.36914 13.6348 9.375 13.4297 9.38672 13.2148L8.13867 14.2578C8.08398 14.3008 8.0293 14.3301 7.97461 14.3457C7.92383 14.3613 7.87109 14.3691 7.81641 14.3691C7.73828 14.3691 7.66602 14.3535 7.59961 14.3223C7.5332 14.2871 7.48438 14.248 7.45312 14.2051L6.98438 13.5605L9.63281 11.3105H10.8457V18.8926H12.4102V20H7.60547V18.8926Z"
            fill="currentColor"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15 15.679C15 15.1267 15.4477 14.679 16 14.679L27.2761 14.679C27.3651 14.1172 27.3651 15.1262 27.3651 15.6785C27.3651 16.2308 27.6148 16.679 27.0625 16.679H16C15.4477 16.679 15 16.2312 15 15.679Z"
            fill="currentColor"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M26.8366 19.0094C26.7626 19.2003 26.9883 19.4291 27.1702 19.3352L33.9047 15.8562C34.049 15.7816 34.049 15.5753 33.9047 15.5008L27.1702 12.0218C26.9883 11.9278 26.7626 12.1567 26.8366 12.3475C27.1624 13.1881 27.3651 14.3697 27.3651 15.6785C27.5 17 27.1624 18.1688 26.8366 19.0094Z"
            fill="currentColor"
        />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconGuided',
})
</script>
